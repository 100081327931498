import MockAdapter from "axios-mock-adapter";
import mockAuth from "../../app/modules/Auth/__mocks__/mockAuth";
import mockCustomers from "../../app/modules/ECommerce/__mocks__/mockCustomer";
import mockAdminCustomer from "../../app/modules/Administration/customer/__mocks__/mockCustomer";
import mockAdminOrganisation from "../../app/modules/Administration/organisation/__mocks__/mockOrganisation";
import mockAdminEmployee from "../../app/modules/Administration/employee/__mocks__/mockEmployee";
import mockAdminVendor from "../../app/modules/Administration/vendor/__mocks__/mockVendor";
import mockAdminRole from "../../app/modules/Administration/role/__mocks__/mockRole";
import mockProducts from "../../app/modules/ECommerce/__mocks__/mockProduct";
import mockRemarks from "../../app/modules/ECommerce/__mocks__/mockRemark";
import mockSpecifications from "../../app/modules/ECommerce/__mocks__/mockSpecification";

export default function mockAxios(axios) {
  const mock = new MockAdapter(axios, { delayResponse: 300 });

  mockAuth(mock);
  mockCustomers(mock);
  mockAdminCustomer(mock);
  mockAdminOrganisation(mock);
  mockAdminEmployee(mock);
  mockAdminVendor(mock);
  mockAdminRole(mock);
  mockProducts(mock);
  mockRemarks(mock);
  mockSpecifications(mock);

  return mock;
}
