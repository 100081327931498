import roleTableMock from "./roleTableMock";
import MockUtils from "./mock.utils";

export default function mockAdminRole(mock) {
  mock.onPost("api/roles").reply(({ data }) => {
    const { role } = JSON.parse(data);
    const {
      roleCode = "ROL-001",
      roleName = "",      
      notes = ""
    } = role;

    const id = generateRoleId();
    const newRole = {
      roleCode,
      roleName,      
      notes
    };
    roleTableMock.push(newRole);
    return [200, { role: newRole }];
  });

  mock.onPost("api/roles/find").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdRoles = mockUtils.baseFilter(
      roleTableMock,
      queryParams
    );
    return [200, filterdRoles];
  });

  mock.onPost("api/roles/deleteRoles").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = roleTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        roleTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/roles/updateStatusForRoles").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    roleTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/roles\/\d+/).reply(config => {
    const id = config.url.match(/api\/roles\/(\d+)/)[1];
    const role = roleTableMock.find(el => el.id === +id);
    if (!role) {
      return [400];
    }

    return [200, role];
  });

  mock.onPut(/api\/roles\/\d+/).reply(config => {
    const id = config.url.match(/api\/roles\/(\d+)/)[1];
    const { role } = JSON.parse(config.data);
    const index = roleTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    roleTableMock[index] = { ...role };
    return [200];
  });

  mock.onDelete(/api\/roles\/\d+/).reply(config => {
    const id = config.url.match(/api\/roles\/(\d+)/)[1];
    const index = roleTableMock.findIndex(el => el.id === +id);
    roleTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateRoleId() {
  const ids = roleTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
