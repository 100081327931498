import React from "react";

const getFieldError = (errors, fieldName) => {
  if (errors !== undefined && Object.keys(errors).length > 0) {
    if (fieldName.includes(".")) {
      var fields = fieldName.split('.');
      fields.forEach(function (item) {
        if (errors !== undefined && Object.keys(errors).length > 0) {
          errors = errors[item]
        }
      });

      return errors;
    }
    else {
      return errors[fieldName];
    }
  }
  return undefined;
}

const getFieldTouched = (touched, fieldName) => {
  if (touched !== undefined && Object.keys(touched).length > 0) {
    if (fieldName.includes(".")) {
      var fields = fieldName.split('.');
      fields.forEach(function (item) {
        if (touched !== undefined && Object.keys(touched).length > 0) {
          touched = touched[item]
        }
      });

      return touched;
    }
    else {
      return touched[fieldName];
    }
  }
  return undefined;
}


const inputLabel = ({ label, touched, error, customFeedbackLabel, field }) => {
  const fieldName = field.name;
  var touchedField = getFieldTouched(touched, fieldName);
  var errorsField = getFieldError(error, fieldName);
  if (touchedField && errorsField) {
    return <div className="invalid-feedback">{errorsField}</div>;
  }

  if (touchedField && !errorsField && label) {
    return <div className="valid-feedback">{label} was entered correct</div>;
  }

  return (
    <div className="feedback">
      {customFeedbackLabel && <>{customFeedbackLabel}</>}
      {!customFeedbackLabel && (
        <>
          Please enter <b>{label}</b>
        </>
      )}
    </div>
  );
};


const dateLabel = ({ label, touched, error, field, customFeedbackLabel }) => {
  const fieldName = field.name;
  var touchedField = getFieldTouched(touched, fieldName);
  var errorsField = getFieldError(error, fieldName);
  if (touchedField && errorsField) {
    return <div className="invalid-feedback">{errorsField}</div>;
  }

  if (touchedField && !errorsField && label) {
    return <div className="valid-feedback">{label} was entered correct</div>;
  }

  return (
    <div className="feedback">
      {customFeedbackLabel && <>{customFeedbackLabel}</>}
      {!customFeedbackLabel && (
        <>
          Please enter <b>{label}</b>
        </>
      )}
    </div>
  );
};

const selectLabel = ({ label, touched, error, field, customFeedbackLabel }) => {
  const fieldName = field.name;
  var touchedField = getFieldTouched(touched, fieldName);
  var errorsField = getFieldError(error, fieldName);
  if (touchedField && errorsField) {
    return <div className="invalid-feedback">{errorsField}</div>;
  }

  return (
    <div className="feedback">
      {customFeedbackLabel && <>{customFeedbackLabel}</>}
      {!customFeedbackLabel && label && (
        <>
          Please select <b>{label}</b>
        </>
      )}
    </div>
  );
};

export function FieldFeedbackLabel({
  label,
  touched,
  error,
  type,
  customFeedbackLabel,
  field
}) {
  switch (type) {
    case "text":
      return inputLabel({ label, touched, error, field, customFeedbackLabel });
    case "email":
      return inputLabel({ label, touched, error, field, customFeedbackLabel });
    case "password":
      return inputLabel({ label, touched, error, field, customFeedbackLabel });
    case "date":
      return dateLabel({ label, touched, error, field, customFeedbackLabel });
    default:
      return selectLabel({ label, touched, error, field, customFeedbackLabel });
  }
}
