import React from "react";
import {useField, useFormikContext} from "formik";
import DatePicker from "react-datepicker";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors, field) => {
  const fieldName = field.name;
  var touchedField = getFieldTouched(touched, fieldName);
  var errorsField = getFieldError(errors, fieldName);
  const classes = ["form-control"];
  if (touchedField && errorsField) {
    classes.push("is-invalid");
  }

  if (touchedField && !errorsField && field.value) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

const getFieldError = (errors, fieldName) => {
  if (errors !== undefined && Object.keys(errors).length > 0) {
    if (fieldName.includes(".")) {
      var fields = fieldName.split('.');
      fields.forEach(function (item) {
        if (errors !== undefined && Object.keys(errors).length > 0) {
          errors = errors[item]
        }
      });

      return errors;
    }
    else {
      return errors[fieldName];
    }
  }
  return undefined;
}

const getFieldTouched = (touched, fieldName) => {
  if (touched !== undefined && Object.keys(touched).length > 0) {
    if (fieldName.includes(".")) {
      var fields = fieldName.split('.');
      fields.forEach(function (item) {
        if (touched !== undefined && Object.keys(touched).length > 0) {
          touched = touched[item]
        }
      });

      return touched;
    }
    else {
      return touched[fieldName];
    }
  }
  return undefined;
}

export function DatePickerField({ withFeedbackLabel = false,
  ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);
  return (
    <>
      {props.label && <label>{props.label} {props.required && <span className="text-danger font-weight-bolder">*</span>}</label>}
      <DatePicker
        className={getFieldCSSClasses(touched, errors, field)}
        style={{ width: "100%" }}
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={val => {
          setFieldValue(field.name, val);
        }}
      />
       {withFeedbackLabel && (
        <FieldFeedbackLabel
          type="date"
          field={field}
          error={errors}
          touched={touched}
          label={"<b>{props.label}</b> in 'mm/dd/yyyy' format"}
          customFeedbackLabel={props.customFeedbackLabel}
        />
      )}
    </>
  );
}
