export default [
  {
    id: 1,
    employeeCode: "EMP-001",
    role: 1,
    name: {first: "Sonni", last: "Gabotti"},
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, 
    email: "sgabotti0@wsj.com",
    phone: "",
    username: "sgabotti0@wsj.com",
    password: "",
    notes: "",
    _userId: 1,
    _createdDate: "09/07/2016",
    _updatedDate: "05/31/2013"
  },
  {
    id: 2,
    employeeCode: "EMP-002",
    role: 0,    
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, 
    email: "acowperthwaite1@storify.com",
    phone: "",
    username: "acowperthwaite1@storify.com",
    password: "",
    notes: "",
    name: {
      first: "Abie",
      last: "Cowperthwaite"      
    }, _userId: 2,
    _createdDate: "03/18/2014",
    _updatedDate: "08/17/2016"
  },
  {
    id: 3,
    employeeCode: "EMP-003",
    role: 4,
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    },
    email: "mstodd2@twitpic.com",
    phone: "",
    username: "mstodd2@twitpic.com",
    password: "",
    notes: "",
    name: {
      first: "Melody",
      last: "Stodd"
    }, _userId: 1,
    _createdDate: "07/03/2015",
    _updatedDate: "01/28/2015"
  },
  {
    id: 4,
    employeeCode: "EMP-004",
    role: 3,
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, 
    phone: "",
    email: "ngalbreth3@springer.com",
    password: "",
    username: "ngalbreth3@springer.com",
    notes: "",
    name: {
      first: "Naomi",
      last: "Galbreth",
    }, _userId: 2,
    _createdDate: "06/22/2013",
    _updatedDate: "01/31/2011"
  },
  {
    id: 5,
    employeeCode: "EMP-005",
    role: 1,
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    },
    email: "ajandl4@mapy.cz",
    phone: "",
      username: "ajandl4@mapy.cz",
      password: "",
    notes: "",
    name: {
      first: "Ashley",
      last: "Jandl"
    }, _userId: 1,
    _createdDate: "01/30/2018",
    _updatedDate: "05/22/2014"
  },
  {
    id: 6,
    employeeCode: "EMP-006",
    role: 5,
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    },
    email: "mduplan5@msn.com",
    phone: "",
    username: "mduplan5@msn.com",
    password: "",
    notes: "",
    name: {
      first: "Mildrid",
      last: "Duplan"
    }, _userId: 1,
    _createdDate: "03/27/2011",
    _updatedDate: "09/02/2015"
  },
  {
    id: 7,
    employeeCode: "EMP-007",
    role: 8,
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    },
    email: "dstow6@vistaprint.com",
    phone: "",
    username: "dstow6@vistaprint.com",
    password: "",
    notes: "",
    name: {
      first: "Dall",
      last: "Stow"
    }, _userId: 1,
    _createdDate: "09/05/2011",
    _updatedDate: "06/21/2012"
  },
  {
    id: 8,
    employeeCode: "EMP-008",
    role: 6,
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    },
    email: "bdering7@europa.eu",
    phone: "",
    username: "bdering7@europa.eu",
    password: "",
    notes: "",
    name: {
      first: "Burton",
      last: "Dering"
    }, _userId: 1,
    _createdDate: "09/09/2017",
    _updatedDate: "06/27/2011"
  },
  {
    id: 9,
    employeeCode: "EMP-009",
    role: 3,
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    },
    email: "wblackaller8@biblegateway.com",
    phone: "",
    username: "wblackaller8@biblegateway.com",
    password: "",
    notes: "",
    name: {
      first: "Wolf",
      last: "Blackaller"
    }, _userId: 1,
    _createdDate: "07/16/2011",
    _updatedDate: "05/24/2014"
  },
  {
    id: 10,
    employeeCode: "EMP-010",
    role: 1,
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    },
    email: "ahurtic9@friendfeed.com",
    phone: "",
    username: "ahurtic9@friendfeed.com",
    password: "",
    notes: "",
    name: {
      first: "Adham",
      last: "Hurtic"
    }, _userId: 2,
    _createdDate: "03/25/2011",
    _updatedDate: "12/13/2015"
  },
  {
    id: 11,
    employeeCode: "EMP-011",
    role: 5,
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    },
    email: "cscuddersa@shareasale.com",
    phone: "",
    username: "cscuddersa@shareasale.com",
    password: "",
    notes: "",
    name: {
      first: "Carlina",
      last: "Scudders"
    }, _userId: 1,
    _createdDate: "11/01/2015",
    _updatedDate: "02/16/2013"
  },
  {
    id: 12,
    employeeCode: "EMP-012",
    role: 3,
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    },
    email: "rlandsbergb@deliciousdays.com",
    phone: "",
    username: "rlandsbergb@deliciousdays.com",
    password: "",
    notes: "",
    name: {
      first: "Roderich",
      last: "Landsberg"
    }, _userId: 2,
    _createdDate: "12/12/2017",
    _updatedDate: "02/22/2013"
  },
  {
    id: 13,
    employeeCode: "EMP-013",
    role: 7,
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    },
    email: "mtremblettc@printfriendly.com",
    phone: "",
    username: "mtremblettc@printfriendly.com",
    password: "",
    notes: "",
    name: {
      first: "Mychal",
      last: "Tremblett"
    }, _userId: 1,
    _createdDate: "08/14/2014",
    _updatedDate: "08/03/2014"
  }];
