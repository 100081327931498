import vendorTableMock from "./vendorTableMock";
import MockUtils from "./mock.utils";

export default function mockAdminVendor(mock) {
  mock.onPost("api/vendors").reply(({ data }) => {
    const { vendor } = JSON.parse(data);
    const {
      vendorCode = "VND-001",
      type = 0,
      vendorName = "",
      address: {
        line1 = "",
        line2 = "",
        city = "",
        state = 0,
        country = 0,
        pincode = ""
      },
      contact: {
        firstName = "",
        lastName = "",
        phone = "",
        email = "" },
      taxId = "",
      gstNo = "",
      notes = ""
    } = vendor;

    const id = generateVendorId();
    const newVendor = {
      vendorCode,
      type,
      vendorName,
      address: {
        line1,
        line2,
        city,
        state,
        country,
        pincode
      },
      contact: {
        firstName,
        lastName,
        phone,
        email
      },
      taxId,
      gstNo,
      notes
    };
    vendorTableMock.push(newVendor);
    return [200, { vendor: newVendor }];
  });

  mock.onPost("api/vendors/find").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdVendors = mockUtils.baseFilter(
      vendorTableMock,
      queryParams
    );
    return [200, filterdVendors];
  });

  mock.onPost("api/vendors/deleteVendors").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = vendorTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        vendorTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/vendors/updateStatusForVendors").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    vendorTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/vendors\/\d+/).reply(config => {
    const id = config.url.match(/api\/vendors\/(\d+)/)[1];
    const vendor = vendorTableMock.find(el => el.id === +id);
    if (!vendor) {
      return [400];
    }

    return [200, vendor];
  });

  mock.onPut(/api\/vendors\/\d+/).reply(config => {
    const id = config.url.match(/api\/vendors\/(\d+)/)[1];
    const { vendor } = JSON.parse(config.data);
    const index = vendorTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    vendorTableMock[index] = { ...vendor };
    return [200];
  });

  mock.onDelete(/api\/vendors\/\d+/).reply(config => {
    const id = config.url.match(/api\/vendors\/(\d+)/)[1];
    const index = vendorTableMock.findIndex(el => el.id === +id);
    vendorTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateVendorId() {
  const ids = vendorTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
