export default [
  {
    id: 1,
    vendorCode: "VND-001",
    type: 1,
    vendorName: "Sonni Gabotti",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, 
    taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Sonni",
      lastName: "Gabotti",
      email: "sgabotti0@wsj.com",
      phone: "",
    }, _userId: 1,
    _createdDate: "09/07/2016",
    _updatedDate: "05/31/2013"
  },  
  {
    id: 2,
    vendorCode: "VND-002",
    type: 0,
    vendorName: "acowperthwaite1",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Abie",
      lastName: "Cowperthwaite",
      email: "acowperthwaite1@storify.com",
      phone: "",
    }, _userId: 2,
    _createdDate: "03/18/2014",
    _updatedDate: "08/17/2016"
  },
  {
    id: 3,
    vendorCode: "VND-003",
    type: 1,
    vendorName: "mstodd2",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Melody",
      lastName: "Stodd",
      email: "mstodd2@twitpic.com",
      phone: "",
    }, _userId: 1,
    _createdDate: "07/03/2015",
    _updatedDate: "01/28/2015"
  },
  {
    id: 4,
    vendorCode: "VND-004",
    type: 0,
    vendorName: "Naomi Galbreth",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Naomi",
      lastName: "Galbreth",
      email: "ngalbreth3@springer.com",
      phone: "",
    }, _userId: 2,
    _createdDate: "06/22/2013",
    _updatedDate: "01/31/2011"
  },
  {
    id: 5,
    vendorCode: "VND-005",
    type: 1,
    vendorName: "Ashley Jandl",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Ashley",
      lastName: "Jandl",
      email: "ajandl4@mapy.cz",
      phone: "",
    }, _userId: 1,
    _createdDate: "01/30/2018",
    _updatedDate: "05/22/2014"
  },
  {
    id: 6,
    vendorCode: "VND-006",
    type: 0,
    vendorName: "Mildrid Duplan",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Mildrid",
      lastName: "Duplan",
      email: "mduplan5@msn.com",
      phone: "",
    }, _userId: 1,
    _createdDate: "03/27/2011",
    _updatedDate: "09/02/2015"
  },
  {
    id: 7,
    vendorCode: "VND-007",
    type: 1,
    vendorName: "Dall Stow",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Dall",
      lastName: "Stow",
      email: "dstow6@vistaprint.com",
      phone: "",
    }, _userId: 1,
    _createdDate: "09/05/2011",
    _updatedDate: "06/21/2012"
  },
  {
    id: 8,
    vendorCode: "VND-008",
    type: 0,
    vendorName: "bdering7",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Burton",
      lastName: "Dering",
      email: "bdering7@europa.eu",
      phone: "",
    }, _userId: 1,
    _createdDate: "09/09/2017",
    _updatedDate: "06/27/2011"
  },
  {
    id: 9,
    vendorCode: "VND-009",
    type: 0,
    vendorName: "Wolf Gabotti",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Wolf",
      lastName: "Blackaller",
      email: "wblackaller8@biblegateway.com",
      phone: "",
    }, _userId: 1,
    _createdDate: "07/16/2011",
    _updatedDate: "05/24/2014"
  },
  {
    id: 10,
    vendorCode: "VND-010",
    type: 1,
    vendorName: "Adham Hurtic",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Adham",
      lastName: "Hurtic",
      email: "ahurtic9@friendfeed.com",
      phone: "",
    }, _userId: 2,
    _createdDate: "03/25/2011",
    _updatedDate: "12/13/2015"
  },
  {
    id: 11,
    vendorCode: "VND-011",
    type: 0,
    vendorName: "Carlina Scudders",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Carlina",
      lastName: "Scudders",
      email: "cscuddersa@shareasale.com",
      phone: "",
    }, _userId: 1,
    _createdDate: "11/01/2015",
    _updatedDate: "02/16/2013"
  },
  {
    id: 12,
    vendorCode: "VND-012",
    type: 1,
    vendorName: "Roderich Landsberg",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Roderich",
      lastName: "Landsberg",
      email: "rlandsbergb@deliciousdays.com",
      phone: "",
    }, _userId: 2,
    _createdDate: "12/12/2017",
    _updatedDate: "02/22/2013"
  },
  {
    id: 13,
    vendorCode: "VND-013",
    type: 1,
    vendorName: "Mychal Tremblett",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Mychal",
      lastName: "Tremblett",
      email: "mtremblettc@printfriendly.com",
      phone: "",
    }, _userId: 1,
    _createdDate: "08/14/2014",
    _updatedDate: "08/03/2014"
  },
  {
    id: 14,
    vendorCode: "VND-014",
    type: 1,
    vendorName: "Corine Rajchert",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Corine",
      lastName: "Rajchert",
      email: "crajchertd@google.com",
      phone: "",
    }, _userId: 2,
    _createdDate: "12/07/2010",
    _updatedDate: "09/24/2012"
  },
  {
    id: 15,
    vendorCode: "VND-015",
    type: 1,
    vendorName: "Worth McFarlane",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Worth",
      lastName: "McFarlane",
      email: "wmcfarlanee@cocolog-nifty.com",
      phone: "",
    }, _userId: 1,
    _createdDate: "02/23/2013",
    _updatedDate: "09/24/2016"
  },
  {
    id: 16,
    vendorCode: "VND-016",
    type: 1,
    vendorName: "Ogden Danielski",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Ogden",
      lastName: "Danielski",
      email: "odanielskif@goo.ne.jp",
      phone: "",
    }, _userId: 1,
    _createdDate: "09/08/2012",
    _updatedDate: "10/29/2017"
  },
  {
    id: 17,
    vendorCode: "VND-017",
    type: 0,
    vendorName: "ltiftg",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Laurette",
      lastName: "Tift",
      email: "ltiftg@about.me",
      phone: "",
    }, _userId: 2,
    _createdDate: "05/03/2016",
    _updatedDate: "08/02/2011"
  },
  {
    id: 18,
    vendorCode: "VND-018",
    type: 0,
    vendorName: "ejacksonh",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Eleanora",
      lastName: "Jackson",
      email: "ejacksonh@geocities.jp",
      phone: "",
    }, _userId: 1,
    _createdDate: "09/25/2017",
    _updatedDate: "01/08/2012"
  },
  {
    id: 19,
    vendorCode: "VND-019",
    type: 1,
    vendorName: "Manon Perott",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Manon",
      lastName: "Perott",
      email: "mperotti@nyu.edu",
      phone: "",
    }, _userId: 2,
    _createdDate: "04/26/2013",
    _updatedDate: "02/24/2012"
  },
  {
    id: 20,
    vendorCode: "VND-020",
    type: 1,
    vendorName: "Karney Damerell",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Karney",
      lastName: "Damerell",
      email: "kdamerellj@booking.com",
      phone: "",
    }, _userId: 2,
    _createdDate: "11/28/2014",
    _updatedDate: "10/11/2012"
  },
  {
    id: 21,
    vendorCode: "VND-021",
    type: 1,
    vendorName: "Chelsea Bruce",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Chelsea",
      lastName: "Bruce",
      email: "cbrucek@icq.com",
      phone: "",
    }, _userId: 1,
    _createdDate: "07/29/2013",
    _updatedDate: "12/01/2017"
  },
  {
    id: 22,
    vendorCode: "VND-022",
    type: 1,
    vendorName: "Barny Verny",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Barny",
      lastName: "Verny",
      email: "bvernyl@bing.com",
      phone: "",
    }, _userId: 1,
    _createdDate: "06/14/2011",
    _updatedDate: "06/28/2011"
  },
  {
    id: 23,
    vendorCode: "VND-023",
    type: 1,
    vendorName: "Elita Matuszewski",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Elita",
      lastName: "Matuszewski",
      email: "ematuszewskim@businesswire.com",
      phone: "",
    }, _userId: 2,
    _createdDate: "05/19/2016",
    _updatedDate: "09/12/2011"
  },
  {
    id: 24,
    vendorCode: "VND-024",
    type: 1,
    vendorName: "Chelsey Scullard",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Chelsey",
      lastName: "Scullard",
      email: "cscullardn@buzzfeed.com",
      phone: "",
    }, _userId: 1,
    _createdDate: "02/09/2013",
    _updatedDate: "12/11/2011"
  },
  {
    id: 25,
    vendorCode: "VND-025",
    type: 0,
    vendorName: "Jodi Olphert",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Jodi",
      lastName: "Olphert",
      email: "jolpherto@elpais.com",
      phone: "",
    }, _userId: 2,
    _createdDate: "07/28/2017",
    _updatedDate: "05/03/2017"
  },
  {
    id: 26,
    vendorCode: "VND-026",
    type: 1,
    vendorName: "Micah Searchfield",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Micah",
      lastName: "Searchfield",
      email: "msearchfieldp@instagram.com",
      phone: "",
    }, _userId: 1,
    _createdDate: "07/05/2011",
    _updatedDate: "02/19/2013"
  },
  {
    id: 27,
    vendorCode: "VND-027",
    type: 1,
    vendorName: "fodoughertyq",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Freeland",
      lastName: "O'Dougherty",
      email: "fodoughertyq@cbsnews.com",
      phone: "",
    }, _userId: 1,
    _createdDate: "06/09/2012",
    _updatedDate: "03/15/2014"
  },
  {
    id: 28,
    vendorCode: "VND-028",
    type: 1,
    vendorName: "Marna Dacre",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Marna",
      lastName: "Dacre",
      email: "mdacrer@tmall.com",
      phone: "",
    }, _userId: 2,
    _createdDate: "12/30/2011",
    _updatedDate: "03/24/2016"
  },
  {
    id: 29,
    vendorCode: "VND-029",
    type: 1,
    vendorName: "Carlita Calwell",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Carlita",
      lastName: "Calwell",
      email: "ccalwells@1688.com",
      phone: "",
    }, _userId: 2,
    _createdDate: "02/28/2016",
    _updatedDate: "02/25/2013"
  },
  {
    id: 30,
    vendorCode: "VND-030",
    type: 1,
    vendorName: "Deonne Castellan",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Deonne",
      lastName: "Castellan",
      email: "dcastellant@gizmodo.com",
      phone: "",
    }, _userId: 2,
    _createdDate: "03/12/2011",
    _updatedDate: "03/26/2017"
  },
  {
    id: 31,
    vendorCode: "VND-031",
    type: 1,
    vendorName: "Cesare Chestnutt",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Cesare",
      lastName: "Chestnutt",
      email: "cchestnuttu@cornell.edu",
      phone: "",
    }, _userId: 1,
    _createdDate: "03/06/2012",
    _updatedDate: "09/08/2012"
  },
  {
    id: 32,
    vendorCode: "VND-032",
    type: 1,
    vendorName: "glupartov",
    address: {
      line1: "Test",
      line2: "",
      city: "Test",
      state: 0,
      country: 0,
      pincode: "",
    }, taxId: "",
    gstNo: "",
    notes: "",
    contact: {
      firstName: "Gauthier",
      lastName: "Luparto",
      email: "glupartov@wordpress.org",
      phone: "",
    }, _userId: 2,
    _createdDate: "04/28/2013",
    _updatedDate: "02/26/2016"
  }
];
