import organisationTableMock from "./organisationTableMock";
import MockUtils from "./mock.utils";

export default function mockAdminOrganisation(mock) {
  mock.onPost("api/organisations").reply(({ data }) => {
    const { organisation } = JSON.parse(data);
    const {
      organisationCode = "Cust-001",
      type = 0,
      organisationName = "",
      address: {
        line1 = "",
        line2 = "",
        city = "",
        state = 0,
        country = 0,
        pincode = ""
      },
      contact: {
        firstName = "",
        lastName = "",
        phone = "",
        email = "" },
      organisationEmail = "",
      organisationPhone = "",  
      taxId = "",
      gstNo = "",
      notes = ""
    } = organisation;

    const id = generateOrganisationId();
    const newOrganisation = {
      organisationCode,
      type,
      organisationName,
      address: {
        line1,
        line2,
        city,
        state,
        country,
        pincode
      },
      contact: {
        firstName,
        lastName,
        phone,
        email
      },
      organisationEmail,
      organisationPhone,  
      taxId,
      gstNo,
      notes
    };
    organisationTableMock.push(newOrganisation);
    return [200, { organisation: newOrganisation }];
  });

  mock.onPost("api/organisations/find").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdOrganisations = mockUtils.baseFilter(
      organisationTableMock,
      queryParams
    );
    return [200, filterdOrganisations];
  });

  mock.onPost("api/organisations/deleteOrganisations").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = organisationTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        organisationTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/organisations/updateStatusForOrganisations").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    organisationTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/organisations\/\d+/).reply(config => {
    const id = config.url.match(/api\/organisations\/(\d+)/)[1];
    const organisation = organisationTableMock.find(el => el.id === +id);
    if (!organisation) {
      return [400];
    }

    return [200, organisation];
  });

  mock.onPut(/api\/organisations\/\d+/).reply(config => {
    const id = config.url.match(/api\/organisations\/(\d+)/)[1];
    const { organisation } = JSON.parse(config.data);
    const index = organisationTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    organisationTableMock[index] = { ...organisation };
    return [200];
  });

  mock.onDelete(/api\/organisations\/\d+/).reply(config => {
    const id = config.url.match(/api\/organisations\/(\d+)/)[1];
    const index = organisationTableMock.findIndex(el => el.id === +id);
    organisationTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateOrganisationId() {
  const ids = organisationTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
