import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {customersSlice} from "../app/modules/Administration/customer/_redux/customersSlice";
import {organisationsSlice} from "../app/modules/Administration/organisation/_redux/organisationsSlice";
import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
import {vendorsSlice} from "../app/modules/Administration/vendor/_redux/vendorsSlice";
import {employeesSlice} from "../app/modules/Administration/employee/_redux/employeesSlice";
import {rolesSlice} from "../app/modules/Administration/role/_redux/rolesSlice";
import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  organisations: organisationsSlice.reducer,
  vendors: vendorsSlice.reducer,
  employees: employeesSlice.reducer,
  roles: rolesSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
