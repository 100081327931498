import customerTableMock from "./customerTableMock";
import MockUtils from "./mock.utils";

export default function mockAdminCustomer(mock) {
  mock.onPost("api/customers").reply(({ data }) => {
    const { customer } = JSON.parse(data);
    const {
      customerCode = "Cust-001",
      type = 0,
      customerName = "",
      address: {
        line1 = "",
        line2 = "",
        city = "",
        state = 0,
        country = 0,
        pincode = ""
      },
      contact: {
        firstName = "",
        lastName = "",
        phone = "",
        email = "" },
      taxId = "",
      gstNo = "",
      notes = ""
    } = customer;

    const id = generateCustomerId();
    const newCustomer = {
      customerCode,
      type,
      customerName,
      address: {
        line1,
        line2,
        city,
        state,
        country,
        pincode
      },
      contact: {
        firstName,
        lastName,
        phone,
        email
      },
      taxId,
      gstNo,
      notes
    };
    customerTableMock.push(newCustomer);
    return [200, { customer: newCustomer }];
  });

  mock.onPost("api/customers/find").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/customers/deleteCustomers").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = customerTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/customers/updateStatusForCustomers").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    customerTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/customers\/\d+/).reply(config => {
    const id = config.url.match(/api\/customers\/(\d+)/)[1];
    const customer = customerTableMock.find(el => el.id === +id);
    if (!customer) {
      return [400];
    }

    return [200, customer];
  });

  mock.onPut(/api\/customers\/\d+/).reply(config => {
    const id = config.url.match(/api\/customers\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    const index = customerTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    customerTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/customers\/\d+/).reply(config => {
    const id = config.url.match(/api\/customers\/(\d+)/)[1];
    const index = customerTableMock.findIndex(el => el.id === +id);
    customerTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateCustomerId() {
  const ids = customerTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
