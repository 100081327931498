export default [
  {
    id: 1,
    roleCode: "ROL-001",
    roleName: "Super Admin",
    notes: "",
     _userId: 1,
    _createdDate: "09/07/2016",
    _updatedDate: "05/31/2013"
  },
  {
    id: 2,
    roleCode: "ROL-002",
    roleName: "Admin",
    notes: "",
    _userId: 2,
    _createdDate: "03/18/2014",
    _updatedDate: "08/17/2016"
  },
  {
    id: 3,
    roleCode: "ROL-003",
    roleName: "CEO",
    notes: "",
    _userId: 1,
    _createdDate: "07/03/2015",
    _updatedDate: "01/28/2015"
  },
  {
    id: 4,
    roleCode: "ROL-004",
    roleName: "IT Admin",
    notes: "",
    _userId: 2,
    _createdDate: "06/22/2013",
    _updatedDate: "01/31/2011"
  },
  {
    id: 5,
    roleCode: "ROL-005",
    roleName: "CTO",
    notes: "",
    _userId: 1,
    _createdDate: "01/30/2018",
    _updatedDate: "05/22/2014"
  },
  {
    id: 6,
    roleCode: "ROL-006",
    roleName: "Manager",
    notes: "",
    _userId: 1,
    _createdDate: "03/27/2011",
    _updatedDate: "09/02/2015"
  },
  {
    id: 7,
    roleCode: "ROL-007",
    roleName: "Clerk",
    notes: "",
    _userId: 1,
    _createdDate: "09/05/2011",
    _updatedDate: "06/21/2012"
  },
  {
    id: 8,
    roleCode: "ROL-008",
    roleName: "Security",
    notes: "",
    _userId: 1,
    _createdDate: "09/09/2017",
    _updatedDate: "06/27/2011"
  },
  {
    id: 9,
    roleCode: "ROL-009",
    roleName: "Copier",
    notes: "",
     _userId: 1,
    _createdDate: "07/16/2011",
    _updatedDate: "05/24/2014"
  },
  {
    id: 10,
    roleCode: "ROL-010",
    roleName: "Asst Manager",
    notes: "",
    _userId: 2,
    _createdDate: "03/25/2011",
    _updatedDate: "12/13/2015"
  },
  {
    id: 11,
    roleCode: "ROL-011",
    roleName: "Project Manager",
    notes: "",
     _userId: 1,
    _createdDate: "11/01/2015",
    _updatedDate: "02/16/2013"
  },
  {
    id: 12,
    roleCode: "ROL-012",
    roleName: "Project Lead",
    notes: "",
     _userId: 2,
    _createdDate: "12/12/2017",
    _updatedDate: "02/22/2013"
  },
  {
    id: 13,
    roleCode: "ROL-013",
    roleName: "Support Assistant",
    notes: "",
     _userId: 1,
    _createdDate: "08/14/2014",
    _updatedDate: "08/03/2014"
  },
  {
    id: 14,
    roleCode: "ROL-014",
    roleName: "Assistant",
    notes: "",
    _userId: 2,
    _createdDate: "12/07/2010",
    _updatedDate: "09/24/2012"
  },
  {
    id: 15,
    roleCode: "ROL-015",
    roleName: "Helper",
    notes: "",
     _userId: 1,
    _createdDate: "02/23/2013",
    _updatedDate: "09/24/2016"
  },
  {
    id: 16,
    roleCode: "ROL-016",
    roleName: "Employee",
    notes: "",
     _userId: 1,
    _createdDate: "09/08/2012",
    _updatedDate: "10/29/2017"
  }
];
