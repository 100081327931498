import React from "react";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors, field) => {
  const fieldName = field.name;
  var touchedField = getFieldTouched(touched, fieldName);
  var errorsField = getFieldError(errors, fieldName);
  const classes = ["form-control"];
  if (touchedField && errorsField) {
    classes.push("is-invalid");
  }

  if (touchedField && !errorsField && field.value) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

const getFieldError = (errors, fieldName) => {
  if (errors !== undefined && Object.keys(errors).length > 0) {
    if (fieldName.includes(".")) {
      var fields = fieldName.split('.');
      fields.forEach(function (item) {
        if (errors !== undefined && Object.keys(errors).length > 0) {
          errors = errors[item]
        }
      });

      return errors;
    }
    else {
      return errors[fieldName];
    }
  }
  return undefined;
}

const getFieldTouched = (touched, fieldName) => {
  if (touched !== undefined && Object.keys(touched).length > 0) {
    if (fieldName.includes(".")) {
      var fields = fieldName.split('.');
      fields.forEach(function (item) {
        if (touched !== undefined && Object.keys(touched).length > 0) {
          touched = touched[item]
        }
      });

      return touched;
    }
    else {
      return touched[fieldName];
    }
  }
  return undefined;
}

export function TextArea({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = false,
  customFeedbackLabel,
  required = false,
  ...props
}) {
  return (
    <>
      {label && <label>{label} {required && <span className="text-danger font-weight-bolder">*</span>}</label>}
      <textarea
        className={getFieldCSSClasses(touched, errors, field)}
        {...field}
        {...props}
      ></textarea>
      {withFeedbackLabel && (
        <FieldFeedbackLabel        
          field={field}
          type="text"
          error={errors}
          touched={touched}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
