import React, {useMemo} from "react";
import objectPath from "object-path";
import {useHtmlClassService} from "../../layout";
import {Demo1Dashboard} from "./Demo1Dashboard";
import {Demo2Dashboard} from "./Demo2Dashboard";
import {Demo3Dashboard} from "./Demo3Dashboard";
import {Demo4Dashboard} from "./Demo4Dashboard";
import {Demo5Dashboard} from "./Demo5Dashboard";
import {Demo6Dashboard} from "./Demo6Dashboard";
import {Demo7Dashboard} from "./Demo7Dashboard";

export function Dashboard() {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            theme: objectPath.get(
                uiService.config,
                "theme"
            )};
    }, [uiService]);
    return <>
        {layoutProps.theme === 'theme1' && <Demo1Dashboard />}
        {layoutProps.theme === 'theme2' && <Demo2Dashboard />}
        {layoutProps.theme === 'theme3' && <Demo3Dashboard />}
        {layoutProps.theme === 'theme4' && <Demo4Dashboard />}
        {layoutProps.theme === 'theme5' && <Demo5Dashboard />}
        {layoutProps.theme === 'theme6' && <Demo6Dashboard />}
        {layoutProps.theme === 'theme7' && <Demo7Dashboard />}
    </>;
}
