import employeeTableMock from "./employeeTableMock";
import MockUtils from "./mock.utils";

export default function mockAdminEmployee(mock) {
  mock.onPost("api/employees").reply(({ data }) => {
    const { employee } = JSON.parse(data);
    const {
      employeeCode = "EMP-001",
      type = 0,
      name: { first = "", last = "" },
      address: {
        line1 = "",
        line2 = "",
        city = "",
        state = 0,
        country = 0,
        pincode = ""
      },
      phone = "",
      email = "",
      username = "",
      password = "",
      notes = ""
    } = employee;

    const id = generateEmployeeId();
    const newEmployee = {
      employeeCode,
      type,
      name: {
        first,
        last
      },
      address: {
        line1,
        line2,
        city,
        state,
        country,
        pincode
      },
      phone,
      email,
      username,
      password,
      notes
    };
    employeeTableMock.push(newEmployee);
    return [200, { employee: newEmployee }];
  });

  mock.onPost("api/employees/find").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdEmployees = mockUtils.baseFilter(
      employeeTableMock,
      queryParams
    );
    return [200, filterdEmployees];
  });

  mock.onPost("api/employees/deleteEmployees").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = employeeTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        employeeTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/employees/updateStatusForEmployees").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    employeeTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/employees\/\d+/).reply(config => {
    const id = config.url.match(/api\/employees\/(\d+)/)[1];
    const employee = employeeTableMock.find(el => el.id === +id);
    if (!employee) {
      return [400];
    }

    return [200, employee];
  });

  mock.onPut(/api\/employees\/\d+/).reply(config => {
    const id = config.url.match(/api\/employees\/(\d+)/)[1];
    const { employee } = JSON.parse(config.data);
    const index = employeeTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    employeeTableMock[index] = { ...employee };
    return [200];
  });

  mock.onDelete(/api\/employees\/\d+/).reply(config => {
    const id = config.url.match(/api\/employees\/(\d+)/)[1];
    const index = employeeTableMock.findIndex(el => el.id === +id);
    employeeTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateEmployeeId() {
  const ids = employeeTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
